<template>
  <div class="sorting-items">
    <div v-for="item in sortOptions" :key="'sort_by' + item.label">
      <div class="sorting-item">
        <div class="check-mark-block">
          <img
            src="../../../assets/img/admin/checkmark.png"
            v-if="selectedSortOption.value === item.value"
            alt
            class="check-mark"
          />
        </div>
        <div @click="onClick(item.value, item.label)">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { SORT_OPTIONS } from '../../../constants';

export default {
  name: 'SortingOptionItems',
  computed: mapState(['selectedSortOption']),
  methods: {
    onClick: function(sortField, sortLabel) {
      this.$store.commit('changeSortOption', sortField);
      this.$store.commit('changeSortLabel', sortLabel);
    }
  },
  data: () => {
    return {
      sortOptions: SORT_OPTIONS
    };
  }
};
</script>
<style lang="less" scoped>
.sorting-item {
  display: flex;
  height: 30px;
  padding-left: 15px;
  cursor: pointer;
  .check-mark-block {
    flex-basis: 7%;
  }
}
</style>
