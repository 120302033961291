<template>
    <td @click="onClick(user.id, user.name)" class="bandwidth-column">
        <div v-if="user.role === roles.OWNER.value" class="bandwidth-info">
            <div class="current-cycle">
                <div class="current-cycle-text">
                    Current cycle:
                    <span class="current-cycle-spent">
                        {{
                        formatSize(user.bandwidthCurrentCycle, 0)
                        }}
                    </span>
                    /
                    <span class="current-cycle-allowed">
                        {{
                        formatSize(user.bandwidthLimit, 3)
                        }}
                    </span>
                </div>
                <div v-if="withOverage" class="overage">Overage</div>
            </div>
            <div class="all-time">All Time: {{ formatSize(user.allTimeBandwidth, 3) }}</div>
            <div class="running-avg">
                Running avg.
                <span class="age-amount">({{ ageInMonths }} mon.):</span>
                {{ averageDataAmount }}
            </div>
        </div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
import moment from "moment";
import { filterMixin, onClickMixin } from "../../../../constants/mixins";
import { USER_ROLES } from '../../../../constants/index';

export default {
    name: "BandwidthColumn",
    props: ["user"],
    mixins: [filterMixin],
    extends: onClickMixin,
    data: () => {
        return {
            roles: USER_ROLES
        }
    },
    computed: {
        withOverage: function() {
            const limitInBytes = this.user.bandwidthLimit * 1024 * 1024 * 1024
            return this.user.bandwidthCurrentCycle > limitInBytes
        },
        ageInMonths() {
            return moment().diff(this.user.createdAt, "months");
        },
        averageDataAmount() {
            if (this.ageInMonths) {
                const averageVolume =
                    this.user.allTimeBandwidth / this.ageInMonths;
                const averageWithUnits = this.formatSize(averageVolume, 3);
                return averageWithUnits;
            }
        }
    }
};
</script>
<style lang="less" scoped>
.current-cycle-spent {
    font-weight: 500;
    font-size: 13px;
    color: #21455e;
}
.current-cycle-allowed {
    color: #637c8e;
}

.age-amount {
    color: #647d8e;
}
.current-cycle {
    display: flex;
    align-items: baseline;
}
.overage {
    margin-left: 10px;
    color: #ee5951;
    background-color: #fff8f8;
    border-radius: 20px;
    padding: 5px 16px;
    font-size: 12px;
}
</style>
