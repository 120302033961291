<template>
    <td @click="onClick(user.id, user.name)" class="business-info-column">
        <div v-if="infoAvailable" class="company-info">
            <div v-if="user.company" class="company-name">{{ user.company }}</div>
            <div class="company-address">
                {{ `${user.street} ${user.city}, ${user.state} ${user.zipCode} ${user.country}` }}
            </div>
        </div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
import { onClickMixin } from '../../../../constants/mixins';

export default {
    name: 'BusinessInfoColumn',
    props: ['user'],
    extends: onClickMixin,
    computed: {
        infoAvailable: function() {
            return this.user.company || this.user.street || this.user.city || this.user.country || this.user.state || this.user.zipCode
        }
    }
};
</script>
<style lang="less" scoped>
.company-info {
    width: 150px;
}
.company-name {
    font-weight: 500;
    font-size: 14px;
}
.company-address {
    margin-top: 9px;
    font-size: 13px;
}
</style>
