<template>
  <div class="table">
    <div class="users-header"></div>
    <div class="table-sorting-menu">
      <div @click="closePopovers" class="option sort-by-option" :class="addMargin">
        <single-select-popover
          :selectedOption="this.$store.state.selectedSortOption.label"
          :sortingOrder="true"
          :searchBar="false"
        >
          <sorting-option-items slot="selectOptions"></sorting-option-items>
          <sort-by-date slot="icon"></sort-by-date>
        </single-select-popover>
      </div>
      <div
        @click="visibility.filter = !visibility.filter"
        class="option filter-option"
        :class="addMargin"
      >
        <multiple-select-popover
          :buttonName="'Filter'"
          :on-done="onItemChange"
          :on-cancel="onCancelFilter"
          :visible="visibility.filter"
          v-on:cancel-pushed="visibility.filter = false"
          v-on:done-pushed="visibility.filter = false"
        >
          <filter-icon slot="icon"></filter-icon>
          <filter-option-items slot="selectOptions" @changeFilter="onChangeFilter($event)" />
        </multiple-select-popover>
      </div>
      <div @click="closePopovers" class="option by-tags-option" :class="classForTagsOption">
        <div class="tag-option-text">
          <div class>Tags</div>
        </div>

        <single-select-popover
          :selectedOption="
                        this.$store.state.tags.selectedTagOption || defaultTag
                    "
          :sortingOrder="false"
          :searchBar="true"
        >
          <tag-items slot="selectOptions" @on-change="onItemChange" />
        </single-select-popover>
      </div>

      <div
        @click="closePopovers"
        v-if="$route.params.section !== 'account-users'"
        class="option status-option"
      >
        <div class="plan-status-text">
          <div>Plan Status</div>
        </div>
        <single-select-popover
          :selectedOption="plans[selectedPlanOption] || 'All Statuses'"
          :sortingOrder="false"
          :searchBar="false"
        >
          <status-items @plan-status-set="onItemChange" slot="selectOptions"></status-items>
        </single-select-popover>
      </div>
      <div
        @click="closePopovers"
        v-if="$route.params.section !== 'account-users'"
        class="option timeline-option"
      >
        <div class="timeline-text">
          <div>Timeline:</div>
        </div>
        <div class="timeline-select">
          <custom-date-range-picker
            :condition-item="conditionTime"
            :show-range="true"
            v-on:change-date-range-picker-value="getNewDateRange"
            parent-el="#main_menu"
          ></custom-date-range-picker>
        </div>
      </div>
      <div
        @click="visibility.customizeColumns = !visibility.customizeColumns"
        class="option customize-option"
      >
        <multiple-select-popover
          :onCancel="columnsOnCancel"
          :onDone="columnsOnDone"
          :buttonName="'Customize Columns'"
          v-on:cancel-pushed="orderColumns"
          v-on:done-pushed="updateSortingOrder"
          :visible="visibility.customizeColumns"
        >
          <div slot="circle" class="column-amount-circle">{{ columnsChecked?.length }}</div>
          <column-items v-on:reset-defaults-click="restoreInitialColumnsOrder" slot="selectOptions"></column-items>
        </multiple-select-popover>
      </div>
    </div>
    <div class="table-wrapper">
      <table-columns :users="users" :columns="columnsChecked">
        <template slot="User" slot-scope="slotProps">
          <user-column :user="slotProps.user"></user-column>
        </template>
        <template slot="Related Users" slot-scope="slotProps">
          <related-users-column :user="slotProps.user"></related-users-column>
        </template>
        <template slot="Plan" slot-scope="slotProps">
          <plan-column :user="slotProps.user"></plan-column>
        </template>
        <template slot="Subscription Status" slot-scope="slotProps">
          <subscription-status-column :user="slotProps.user"></subscription-status-column>
        </template>
        <template slot="Location" slot-scope="slotProps">
          <location-column :user="slotProps.user"></location-column>
        </template>
        <template slot="Last Activity" slot-scope="slotProps">
          <show-date-column :user="slotProps.user" :date="slotProps.user.lastActivity"></show-date-column>
        </template>
        <template slot="Projects" slot-scope="slotProps">
          <projects-column :user="slotProps.user"></projects-column>
        </template>
        <template slot="Role" slot-scope="slotProps">
          <role-column :user="slotProps.user"></role-column>
        </template>
        <template slot="Tags" slot-scope="slotProps">
          <tags-column :user="slotProps.user"></tags-column>
        </template>
        <template slot="Stage Page" slot-scope="slotProps">
          <stage-page-column :user="slotProps.user.stageUrl"></stage-page-column>
        </template>
        <template slot="Business Info" slot-scope="slotProps">
          <business-info-column :user="slotProps.user"></business-info-column>
        </template>
        <template slot="Contact Size" slot-scope="slotProps">
          <contact-size-column :user="slotProps.user"></contact-size-column>
        </template>
        <template slot="Views" slot-scope="slotProps">
          <views-column :user="slotProps.user"></views-column>
        </template>
        <template slot="Service Cost" slot-scope="slotProps">
          <service-cost-column :user="slotProps.user"></service-cost-column>
        </template>
        <template slot="Bandwidth" slot-scope="slotProps">
          <bandwidth-column :user="slotProps.user"></bandwidth-column>
        </template>
        <template slot="Storage" slot-scope="slotProps">
          <storage-column :user="slotProps.user"></storage-column>
        </template>
        <template slot="Referral" slot-scope="slotProps">
          <show-web-address-column :role="slotProps.user.role" :address="slotProps.user.referral"></show-web-address-column>
        </template>
        <template slot="Age(Member since)" slot-scope="slotProps">
          <show-date-column :user="slotProps.user" :date="slotProps.user.createdAt"></show-date-column>
        </template>
        <template slot="Signup Page" slot-scope="slotProps">
          <show-web-address-column :role="slotProps.user.role" :address="slotProps.user.signUpPage"></show-web-address-column>
        </template>
        <template slot="Compliance" slot-scope="slotProps">
          <compliance-column :user="slotProps.user"></compliance-column>
        </template>
      </table-columns>
      <div
        v-if="isLoading"
        v-bind:class="
                    `text-center loading-space loading-space-users ${
                        isLoading && users?.length ? 'overlay' : ''
                    }`
                "
      >
        <i class="el-icon-loading"></i>
      </div>
    </div>

    <table-pagination :pagination="pagination" @on-change="onChangePagination" />
  </div>
</template>
<script>
import SingleSelectPopover from "./SingleSelectPopover";
import SortByDate from "../SvgComponents/SortByDate";
import SortingOptionItems from "./SortingOptionItems";
import TagItems from "./TagItems.vue";
import StatusItems from "./StatusItems.vue";
import CustomDateRangePicker from "../../Contacts/Items/CustomDateRangePicker";
import FilterIcon from "../SvgComponents/FilterIcon";
import MultipleSelectPopover from "./MultipleSelectPopover";
import FilterOptionItems from "./FilterOptionItems";
import ColumnItems from "./ColumnItems";
import TableColumns from "./TableColumns";
import TablePagination from "./TablePagination";
import UsersCount from "./UsersCount";
import UserSearchBar from "./UserSearchBar";
import RoundedButton from "../../UI/RoundedButton";
import {
  PAGINATION_LIMITS,
  DATE_FORMATS,
  USERS_TABLE_PARAMS,
  PLAN_STATUSES
} from "../../../constants";

import { mapState } from "vuex";
import moment from "moment";
import Sortable from "sortablejs";
import UserColumn from "./table columns/UserColumn";
import RelatedUsersColumn from "./table columns/RelatedUsersColumn";
import PlanColumn from "./table columns/PlanColumn";
import SubscriptionStatusColumn from "./table columns/SubscriptionStatusColumn";
import LocationColumn from "./table columns/LocationColumn";
import ShowDateColumn from "./table columns/ShowDateColumn";
import ProjectsColumn from "./table columns/ProjectsColumn";
import RoleColumn from "./table columns/RoleColumn";
import TagsColumn from "./table columns/TagsColumn";
import BusinessInfoColumn from "./table columns/BusinessInfoColumn";
import ContactSizeColumn from "./table columns/ContactSizeColumn";
import ViewsColumn from "./table columns/ViewsColumn";
import ServiceCostColumn from "./table columns/ServiceCostColumn";
import BandwidthColumn from "./table columns/BandwidthColumn";
import StorageColumn from "./table columns/StorageColumn";
import ShowWebAddressColumn from "./table columns/ShowWebAddressColumn";
import ComplianceColumn from "./table columns/ComplianceColumn";
import StagePageColumn from "./table columns/StagePageColumn";

const cur_date = new Date();
const conditionTime = {
  timeline_type: "any_time",
  start_date: moment("2018-09-01", "YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD")
};

export default {
  name: "UsersTable",
  components: {
    SingleSelectPopover,
    SortByDate,
    SortingOptionItems,
    TagItems,
    StatusItems,
    CustomDateRangePicker,
    FilterIcon,
    MultipleSelectPopover,
    FilterOptionItems,
    ColumnItems,
    TableColumns,
    UserColumn,
    RelatedUsersColumn,
    PlanColumn,
    SubscriptionStatusColumn,
    LocationColumn,
    ShowDateColumn,
    ProjectsColumn,
    RoleColumn,
    TablePagination,
    UsersCount,
    UserSearchBar,
    RoundedButton,
    TagsColumn,
    BusinessInfoColumn,
    ContactSizeColumn,
    ViewsColumn,
    ServiceCostColumn,
    BandwidthColumn,
    StorageColumn,
    ShowWebAddressColumn,
    ComplianceColumn,
    StagePageColumn
  },
  data: () => {
    return {
      sortable: {},
      sortingOrder: [],
      conditionTime,
      defaultTag: "All tags",
      filter: {},
      sort: {
        byDate: "desc"
      },
      pagination: {
        offset: 0,
        limit: PAGINATION_LIMITS[0]
      },
      visibility: {
        filter: false,
        customizeColumns: false
      },
      plans: PLAN_STATUSES
    };
  },
  watch: {
    "selectedSortOption.value": function(newVal) {
      const [order] = Object.values(this.sort);
      this.sort = {
        [newVal]: order
      };
      this.onItemChange();
    },
    "selectedSortOption.order": function(newVal) {
      const [fieldName] = Object.keys(this.sort);
      this.sort = {
        [fieldName]: newVal
      };
      this.onItemChange();
    },
    section: function(newVal, oldVal) {
      console.log(USERS_TABLE_PARAMS, newVal)
      const section = USERS_TABLE_PARAMS[newVal] ? USERS_TABLE_PARAMS[newVal].stateSection : USERS_TABLE_PARAMS['active'].stateSection;
      this.$store.commit("addCurrentSection", section);
      this.resetPagination();
      this.resetFilter();
      this.resetTags();
      this.resetSearch();
      this.resetPlanStatus();
      this.getUsers(this.id);
      if (oldVal === "suspended" && newVal === "account-users") {
        this.relatedUsersClick();
      }
    },
    search: function() {
      this.onItemChange();
    },
    "visibility.filter": function(newVal) {
      if (newVal) {
        this.visibility.customizeColumns
          ? (this.visibility.customizeColumns = false)
          : null;
      }
    },
    "visibility.customizeColumns": function(newVal) {
      if (newVal) {
        this.visibility.filter ? (this.visibility.filter = false) : null;
      }
    }
  },
  computed: {
    section: function() {
      return this.$route.params.section;
    },
    ...mapState({
      availableColumns: state => state[state.currentSection].availableColumns,

      columnsChecked: state =>
        state[state.currentSection].checkedColumnsForTable,

      users: state => state.users.usersList,
      selectedSortOption: state => state.selectedSortOption,
      isLoading: state => state.users.isLoading,
      checkedInMenu: state => state[state.currentSection].checkedColumnsForMenu,
      stateSection: state => state.currentSection,
      search: state => state.users.search,
      selectedPlanOption: state => state.plansMenuItem.selectedStatusOption
    }),
    status: function() {
      switch (this.section) {
        case "active":
        case "account-users":
          return "active";
        case "suspended":
          return "suspended";
        case "deleted":
          return "deleted";
      }
    },
    classForTagsOption: function() {
      return this.$route.params.section === "account-users" ? "move-left" : "";
    },
    addMargin: function() {
      return this.$route.params.section === "account-users" ? "add-margin" : "";
    },
    id: function() {
      return this.$route.params.id || 0;
    }
  },
  mounted() {
    this.getUsers(this.id).then(res => {
      const localStorageColumns = JSON.parse(localStorage.getItem("vuex"))[
        this.stateSection
      ].availableColumns;
      this.fillInitialSortingOrder(localStorageColumns);
    });
    const el = document.querySelector(".checkbox-items");
    this.sortable = Sortable.create(el, {
      draggable: ".checkbox-item",
      direction: "vertical",
      dataIdAttr: "data-id",
      onUpdate: function(evt) {
        const children = document.querySelector(".checkbox-items").children;
        const arr = Array.from(children);
        const childrenArr = arr.map(item => {
          return {
            name: item.dataset.name,
            id: item.dataset.id
          };
        });
        this.order = childrenArr;
        this.passNewColumnsOrder();
        this.sortingOrder = this.toArray();
      }
    });
    this.sortable.passNewColumnsOrder = this.passNewColumnsOrder.bind(this);
  },
  methods: {
    restoreInitialColumnsOrder() {
      this.sortingOrder.sort((a, b) => a - b);
      this.orderColumns();
    },
    fillInitialSortingOrder(fillFrom) {
      this.sortingOrder = fillFrom.map((item, index) => item.id);
    },
    updateSortingOrder() {
      this.visibility.customizeColumns = false;
      this.sortingOrder = this.sortable.toArray();
    },
    orderColumns() {
      this.visibility.customizeColumns = false;
      this.sortable.sort(this.sortingOrder);
    },
    passNewColumnsOrder() {
      this.$store.commit("addTemporaryOrder", {
        columns: this.sortable.order,
        section: this.stateSection
      });
    },
    columnsOnCancel() {
      this.$store.commit("columnsCancelPushed", this.stateSection);
    },
    columnsOnDone() {
      this.$store.dispatch("onColumnsDonePushed", this.stateSection);
    },

    getNewDateRange(date) {
      const { start_date, end_date } = this.conditionTime;
      if (start_date === end_date) {
        this.filter = {
          ...this.filter,
          createdAt: {
            equal: start_date
          }
        };
      } else {
        const endDateForQuery = moment(end_date)
          .add(1, "d")
          .format("YYYY-MM-DD");
        this.filter = {
          ...this.filter,
          createdAt: {
            between: { from: start_date, to: endDateForQuery }
          }
        };
      }
      this.resetPagination();
      this.getUsers(this.id);
    },
    onSort() {
      this.resetPagination();
      this.getUsers(this.id);
    },
    onCancelFilter() {},
    onChangeFilter(data) {
      this.filter = data;
    },
    onChangePagination(data) {
      this.pagination = data;
      this.getUsers(this.id);
    },
    relatedUsersClick() {
      this.resetPagination();

      return this.$store.dispatch("getUsers", {
        filter: this.filter,
        pagination: this.pagination,
        search: this.search,
        sort: this.sort,
        status: "suspended",
        id: this.id,
        tags: this.checkedInMenu.includes("Tags") ? 1 : 0,
        section: this.stateSection
      });
    },
    getUsers(id) {
      return this.$store.dispatch("getUsers", {
        filter: this.filter,
        pagination: this.pagination,
        search: this.search,
        sort: this.sort,
        status: this.status,
        id,
        tags: this.checkedInMenu.includes("Tags") ? 1 : 0,
        section: this.stateSection
      });
    },
    resetPagination() {
      this.pagination.offset = 0;
    },
    resetFilter() {
      this.filter = {};
    },
    resetTags() {
      this.$store.commit("changeTagOption");
    },
    resetSearch() {
      this.$store.commit("addSearchText", "");
    },
    closePopovers() {
      for (let popover in this.visibility) {
        this.visibility[popover] = false;
      }
    },
    resetPlanStatus() {
      this.$store.commit("changePlanStatus", "");
    },
    onItemChange() {
      this.resetPagination();
      this.getUsers(this.id);
    }
  },
};
</script>
<style lang="less">
.table {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
}
.timeline-select {
  .show-date-range-label {
    text-align: center;
    color: #a3bac6;
    font-size: 13px;
  }
  .el-select {
    padding: 0 20px;
    border-radius: 20px;
    background-color: #f5f6f9;

    margin-left: 10px;
    .el-input {
      .el-input__inner {
        border: 0;
        box-shadow: none;
        font-size: 13px;
        height: 24px !important;
        line-height: 24px !important;
        background-color: transparent;
      }
    }
  }
}
.column-amount-circle {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #21455e;
  color: #ffffff;
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-option {
  align-items: center;
}
.table-sorting-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 15px 0;
  height: 80px;
  padding: 0 30px;
  border-radius: 15px 15px 0 0;
  .move-left {
    margin-right: auto;
  }

  .add-margin {
    margin-right: 18px;
  }
}
.customize-option {
  flex-basis: 16%;

  .button-content {
    .sort-by__value {
      min-width: 120px;
    }
  }
}
.option {
  display: flex;
  align-items: center;
}

.tag-option-text,
.plan-status-text {
  margin-right: 10px;
}

.status-option {
  flex-basis: 18%;
  .button-container {
    flex-basis: 57%;

    .sort-by__value {
      width: 70px;
    }
  }
}

.sort-by-option {
  flex-basis: 10%;
}

.loading-space-users {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;

  i {
    font-size: 34px;
  }

  &.overlay {
    position: absolute;
    top: 0px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.table-wrapper {
  position: relative;
}
</style>
