<template>
    <td @click="onClick(user.id, user.name) " class="service-cost-column">
        <div v-if="user.role === roles.OWNER.value" class="service-cost-info">
            <div class="this-month">
                This month: ${{ user.thisMonth | formatFigure }}
            </div>
            <div class="last-month">
                Last month: ${{ user.lastMonth | formatFigure }}
            </div>
            <div class="running-avg">
                Running avg.
                <span class="age-amount">
                    ( {{ user.createdAt | countMonths }} mon.): 
                </span>
                ${{ user.lastMonth | formatFigure }}
            </div>
            <div class="all-time">
                All time: ${{ user.allTimeServiceCost | formatFigure }}
            </div>
        </div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
import {filterMixin, onClickMixin} from '../../../../constants/mixins';
import { USER_ROLES } from '../../../../constants/index';

export default {
    name: 'ServiceCostColumn',
    props: ['user'],
    mixins: [filterMixin, onClickMixin],
    data: () => {
        return {
            roles: USER_ROLES
        }
    }
};
</script>
<style lang="less" scoped>
.age-amount {
    color: #637c8e;
}
</style>
