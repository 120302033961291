<template>
  <td @click="onClick(user.id, user.name) " class="role-column">
    <span v-if="user.role" class="role-text">{{ user.role }}</span>
    <div v-else>&mdash;</div>
  </td>
</template>
<script>
import { onClickMixin } from '../../../../constants/mixins';

export default {
  name: 'RoleColumn',
  props: ['user'],
  extends: onClickMixin
};
</script>
<style lang="less" scoped>
.role-column {
  .role-text {
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 20px;
    padding: 5px 15px;
    background-color: #f6f7f9;
  }
}
</style>
