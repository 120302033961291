<template>
  <div class="general-panel">
    <div class="column-one"> 
      <breadcrumbs v-if="$route.params.section === 'account-users'" :path="pathFull"></breadcrumbs>
      <users-count :usersType="usersType" :usersCount="usersCount"></users-count>
    </div>
    <div class="column-two">
      <user-search-bar :placeholder="placeholder"></user-search-bar>
      <rounded-button v-if="showButton" :title="title"  @onClick="newUser"></rounded-button>
    </div>
  </div>
</template>
<script>
import UsersCount from "./UsersTab/UsersCount";
import UserSearchBar from "./UsersTab/UserSearchBar";
import RoundedButton from "../UI/RoundedButton";
import ArrowRightIcon from "./SvgComponents/ArrowRightIcon";
import { mapState } from "vuex";
import Breadcrumbs from "../UI/Breadcrumbs";
import { buildBreadcrumbsPath } from "../../constants/functions";
import { isEmpty } from "lodash";

export default {
  name: "UsersGeneralPanel",
  props: ["showButton", "usersType", "title", "placeholder"],
  data: () => {
    return {
      pathFull: ""
    };
  },
  components: {
    UsersCount,
    RoundedButton,
    UserSearchBar,
    ArrowRightIcon,
    Breadcrumbs
  },
  computed: {
    ...mapState({
      name: state => state.users.accountName,
      user: state => state.users.userInfo,
      usersCount: state => state.users.usersCount
    }),
    path: function() {
      return this.$route.path;
    }
  },
  watch: {
    path: function(newVal) {
      if (this.$route.path.includes("account-users")) {
        this.getUserInfo();
      }
    },
    user: function() {
      this.pathFull = buildBreadcrumbsPath(this.$route, this.name);
    }
  },
  mounted() {
    if (!this.user.settings.city && this.path.includes('users')) {
      this.getUserInfo();
    } else {
      this.pathFull = buildBreadcrumbsPath(this.$route, this.name);
    }
  },
  methods: {
    getUserInfo() {
      return this.$store.dispatch("getUserInfo", this.$route.params.id);
    },
    newUser(){
      this.$router.push('/admin/new_user')
    }
  }
};
</script>
<style lang="less" scoped>
.general-panel {
  display: flex;
  width: 1170px;
  margin: 0 auto;
  height: 140px;
  align-items: center;
  justify-content: space-between;

  .column-one {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-basis: 35%;
    height: 100%;
    padding: 25px 0;

    .breadcrumb {
      display: flex;
      flex-basis: 100%;
      align-items: baseline;
      font-size: 14px;
      a {
        color: #21455e;
      }

      a:hover,
      a:focus,
      a:active {
        text-decoration: none;
        color: #21455e;
        outline: none;
      }
      .separator {
        margin: 0 15px;
      }
    }
  }
  .column-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rounded-button {
    text-transform: capitalize;
  }
  .rounded-button {
    margin-left: 30px;
  }
}
</style>
