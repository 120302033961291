<template>
    <div class="button-container">
        <el-popover
            :visible-arrow="true"
            placement="bottom"
            trigger="click"
            popper-class="custom_popover pop_bottom_270 project-popover"
        >
            <el-button
                slot="reference"
                class="single-select-button"
            >
                <div class="button-content">
                    <slot name="icon"></slot>
                    <span class="sort-by__value">{{ selectedOption }}</span>
                    <arrow-down-icon></arrow-down-icon>
                </div>
            </el-button>
            <div v-if="searchBar" class="search-input-block">
                <input
                    type="text"
                    class="search-input-box"
                    placeholder="Search Tags"
                    v-model="searchText"
                    @input="onTagSearchInput"
                />
            </div>

            <slot name="selectOptions"></slot>
            <div v-if="sortingOrder" class="sorting-order-block">
                <div class="sorting-order-text">Sorting order</div>
                <div class="sorting-option-up">
                    <input
                        id="up"
                        class="input-radio"
                        type="radio"
                        label="up"
                        v-model="radio"
                        :value="radioOptions[0]"
                    />
                    <label for="up" class="sorting-option">Up</label>
                </div>
                <div class="sorting-option-down">
                    <input
                        id="down"
                        class="input-radio"
                        type="radio"
                        label="down"
                        v-model="radio"
                        :value="radioOptions[1]"
                    />
                    <label for="down" class="sorting-option">Down</label>
                </div>
            </div>
        </el-popover>
    </div>
</template>
<script>
import ArrowDownIcon from '../SvgComponents/ArrowDownIcon';
import { mapState } from 'vuex';

export default {
    name: 'SingleSelectPopover',
    components: {
        ArrowDownIcon
    },
    props: ['sortingOrder', 'selectedOption', 'searchBar'],
    computed: mapState(['selectedSortOption']),
    data: () => {
        return {
            searchText: '',
            radio: 'desc',
            radioOptions: ['asc', 'desc'],
            visible: false
        };
    },
    watch: {
        radio: function(newVal) {
            this.onOrderChange(newVal);
        }
    },
    methods: {
        onTagSearchInput() {
            this.$store.dispatch('getSearchedTags', this.searchText);
        },

        onOrderChange(order) {
            this.$store.commit('changeSortOrderOption', order);
        }
    }
};
</script>
<style lang="less">
.button-container {
    display: flex;
    flex-basis: 100%;
}

.button-container > span {
    display: flex;
    flex-basis: 100%;
}

.el-button > span {
    display: flex;
    flex-basis: 100%;
}
.el-button {
    padding: 5px 12px 5px 15px !important;
    background-color: var(--color-grey-light);
    border: none;
    color: var(--color-grey);
    font-size: 13px;
    line-height: 17px;
    border-radius: 30px !important;
    display: flex;
    flex-basis: 100%;

    .button-content {
        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .filter__icon {
        margin-right: 10px;
        vertical-align: middle;
    }

    .icon-arrow__down {
        margin-left: 5px;
        vertical-align: middle;
    }

    span {
        vertical-align: middle;
    }

    .sort-by__value {
        display: inline-block;
        width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        text-align: left;
        vertical-align: middle;
        font-size: 13px;
    }
}

.custom_popover {
    border: 1px solid var(--color-blue);
    border-radius: 10px;
    margin-top: 15px;
    padding: 0;
    padding-top: 15px;
    font-size: 14px;
    color: var(--color-content);
    background-color: #ffffff;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 14px;
        height: 14px;
        box-sizing: border-box;
        transform: rotate(45deg);
        border: 1px solid var(--color-blue);
        background-color: white;
        border-radius: 3px;
    }

    .popover-content {
        .el-checkbox-group,
        .el-radio-group {
            max-height: 306px;
            overflow: auto;
            padding-right: 18px;
        }

        .el-checkbox {
            span.el-checkbox__label {
                font-weight: normal;
            }
        }
        .el-checkbox-group {
            .el-checkbox {
                display: flex;
                width: 100%;
                margin-left: 0;
                height: 30px;
                line-height: 1.2;
                align-items: center;

                .el-checkbox__label {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .el-avatar {
                        margin-right: 10px;

                        img {
                            width: 100%;
                        }
                    }

                    .latest-modified {
                        line-height: 20px;
                        height: 40px;
                        text-align: right;
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    &.pop_bottom_180 {
        width: 180px !important;

        &:before {
            top: -7px;
            left: calc((180px - 14px) / 2);
            border-bottom-color: transparent;
            border-right-color: transparent;
        }
    }

    &.pop_bottom_270 {
        width: 270px !important;

        &:before {
            top: -7px;
            left: calc((270px - 14px) / 2);
            border-bottom-color: transparent;
            border-right-color: transparent;
        }

        &.project-popover {
            left: 0;
            top: 30px;
        }
    }

    &.pop_bottom_464 {
        width: 464px !important;

        &:before {
            top: -7px;
            left: calc((464px - 14px) / 2);
            border-bottom-color: transparent;
            border-right-color: transparent;
        }

        &.video-popover {
            left: 110px;
            top: 30px;
        }
    }

    &.pop_top_220x62 {
        width: 220px !important;
        height: 62px !important;

        &:before {
            top: 54px;
            left: calc((220px - 14px) / 2);
            border-top-color: transparent;
            border-left-color: transparent;
        }
    }

    &.pop_top_240x62 {
        width: 240px !important;
        height: 62px !important;

        &:before {
            top: 54px;
            left: calc((240px - 14px) / 2);
            border-top-color: transparent;
            border-left-color: transparent;
        }
    }

    &.pop_top_250x62 {
        width: 250px !important;
        height: 62px !important;

        &:before {
            top: 54px;
            left: calc((250px - 14px) / 2);
            border-top-color: transparent;
            border-left-color: transparent;
        }
    }

    &.pop_top_270x62 {
        width: 270px !important;
        height: 62px !important;

        &:before {
            top: 54px;
            left: calc((270px - 14px) / 2);
            border-top-color: transparent;
            border-left-color: transparent;
        }
    }

    &.pop_top_330x62 {
        width: 330px !important;
        height: 62px !important;
        &:before {
            top: 54px;
            left: calc((320px - 14px) / 2);
            border-top-color: transparent;
            border-left-color: transparent;
        }
    }

    &.pop_top {
        position: absolute;
        width: 100%;
        &:before {
            position: absolute;

            top: calc(100% - 8px);
            left: calc((100% - 14px) / 2);
            border-top-color: transparent;
            border-left-color: transparent;
        }
    }

    .popper__arrow {
        display: none;
    }
}

.custom_popover:focus {
    outline: none;
}

.sorting-order-block {
    border-top: 1px solid #e2e5ed;
    padding: 0 15px;
    height: 80px;
    display: flex;
    flex-wrap: wrap;
    .input-radio {
        display: none;
    }
    .sorting-option-up,
    .sorting-option-down {
        flex-basis: 50%;
        display: flex;
        padding-bottom: 15px;
        justify-content: center;
    }

    .sorting-option {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sorting-option::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #21455e;
        margin-right: 7px;
    }

    .input-radio:checked + label::before {
        margin-right: 7px;
        background-color: #21455e;
        width: 20px;
        height: 20px;
        border: 1px solid #ffffff;
        box-shadow: 0 0 0 1px #21455e;
    }
    .sorting-order-text {
        padding: 10px 0;
        flex-basis: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Helvetica Neue';
        color: #21455e;
    }
}

.search-input-block {
    display: flex;
    justify-content: center;
    align-items: center;
    .search-input-box {
        border-radius: 20px;
        background-image: url('../../../assets/img/stage/icon_search.svg');
        background-position: 8px 8px;
        background-repeat: no-repeat;
        outline: none;
        border: 1px solid #99d6e9;
        padding-left: 30px;
        height: 32px;
        width: 210px;
    }

    .search-input-box::placeholder {
        color: #a3bac6;
        font-size: 13px;
    }
}

.el-button:focus {
    border: 1px solid #21455e;
    color: #778d9d;
}

.single-select-button {
    min-width: 110px;
    border-radius: 20px;
    background-color: #f5f6f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-overflow: ellipsis;
    border: none;
    padding: 3px 14px;
    color: #778d9d;
}

.single-select-button:focus,
.single-select-button:active {
    outline: none;
}
</style>
