<template>
  <svg
    class="filter__icon"
    width="16.913"
    height="8.81"
    viewBox="0 0 16.913 8.81"
  >
    <g
      id="Group_5905"
      data-name="Group 5905"
      transform="translate(-316.85 -248.85)"
    >
      <line
        id="Line_353"
        data-name="Line 353"
        x2="15.613"
        transform="translate(317.5 249.5)"
        fill="none"
        stroke="#778d9d"
        stroke-linecap="round"
        stroke-width="1.3"
      />
      <line
        id="Line_353-2"
        data-name="Line 353"
        x2="12"
        transform="translate(317.5 253.255)"
        fill="none"
        stroke="#778d9d"
        stroke-linecap="round"
        stroke-width="1.3"
      />
      <line
        id="Line_353-3"
        data-name="Line 353"
        x2="8"
        transform="translate(317.5 257.01)"
        fill="none"
        stroke="#778d9d"
        stroke-linecap="round"
        stroke-width="1.3"
      />
    </g>
  </svg>
</template>
<script>
export default {
    name: "SortByDate"
}
</script>
<style lang="less" scoped>
      .filter__icon {
        vertical-align: middle;
      }
</style>
