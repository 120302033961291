<template>
    <td class="stage-page-column">
        <div v-if="address" class="address-info">{{ address }}</div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
export default {
    name: "ShowWebAddressColumn",
    props: ['address'],
}
</script>
<style lang="less" scoped>
.stage-page-info {
    color: #00ACDC;
    font-size: 14px;
    font-weight: 500;
}
</style>