<template>
    <div class="status-items">

        <div v-for="status in statusesList" :key="status.id" @click="onClick(status.planStatus)" class="tag-item">
            <div class="check-mark-block">
                <img
                    src="../../../assets/img/admin/checkmark.png"
                    v-if="selectedStatusOption == status.planStatus"
                    class="check-mark"
                />
            </div>
            <div
                :class="selectedStatusOption === status.planStatus ? 'bold-tag-text' : null"
                class="tag-name"
            >{{ plans[status.planStatus] }}</div>
            <div class="amount-with-tag ml-auto">{{ status.usersCount }}</div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { PLAN_STATUSES } from '../../../constants/index';

export default {
    name: "StatusItems",
    computed: {
        ...mapState({
            selectedStatusOption: state => state.plansMenuItem.selectedStatusOption,
            statusesList: state => state.plansMenuItem.statusesList
        })
    },
    methods: {
        onClick: function(value) {
            this.$store.commit("changePlanStatus", value);
            this.$emit('plan-status-set');
        }
    },
    data: () => {
        return {
            plans: PLAN_STATUSES
        };
    },
    mounted() {
      this.$store.dispatch('getStatuses', this.$route.params.section)
    }
};
</script>
<style lang="less" scoped>
.tag-item {
    flex-basis: 100%;
    display: flex;
    padding-right: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    .bold-tag-text {
        font-weight: 500;
    }
    .amount-with-tag {
        color: #0baed9;
        font-size: 13px;
        font-weight: 500;
    }
    .check-mark-block {
        flex-basis: 15%;
        display: flex;
        justify-content: center;
        .check-mark {
            object-fit: none;
        }
    }
}
</style>
