<template>
  <div class="active-users-block">
    <div class="active-users-text">{{ usersType }} users</div>
    <div class="active-users-number">{{ usersCount }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UsersCount',
  props: ['usersCount', 'usersType']
};
</script>

<style lang="less" scoped>
.active-users-block {
  display: flex;

  .active-users-text::first-letter {
    text-transform: uppercase;
  }
  
  .active-users-text,
  .active-users-number {
    font-weight: bold;
    font-size: 24px;
    font-family: 'Helvetica Neue';
    color: #21455e;
  }
  .active-users-number {
    font-weight: normal;
    margin-left: 8px;
    color: #617b8d;
  }
}
</style>
