<template>
    <td @click="onClick(user.id, user.name)" class="location">
        <span v-if="user.city || user.country">
            <span v-if="user.city">{{ user.city }},</span>
            <span v-else>&mdash;<span>,</span></span>
            <span v-if="user.country">{{ user.country }}</span>
            <span v-else>&mdash;</span>
        </span>
        <span v-else>&mdash;</span>
    </td>
</template>
<script>
import { onClickMixin } from '../../../../constants/mixins';
export default {
    name: 'LocationColumn',
    props: ['user'],
    mixins: [onClickMixin]
};
</script>
<style lang="less" scoped>
.location {
    font-size: 14px;
    font-weight: 500;
}
</style>
