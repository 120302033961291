<template>
  <td @click="onClick(user.id, user.name)" class="views-column">
    <div v-if="user.videoViews" class="views-info">
      <div class="total-views">
        Total Views:
        <span class="views-amount">{{ user.videoViews | formatFigure }}</span>
      </div>
      <div class="monthly-average">
        Monthly average
        <span class="months-amount">
          {{
          `(${ age } mon.): `
          }}
        </span>
        {{ monthlyAverage }}
      </div>
      <div class="total-watchtime">Total Watchtime: {{ user.totalWatchTime | formatTime }}</div>
    </div>
    <div v-else>&mdash;</div>
  </td>
</template>
<script>
import { filterMixin, onClickMixin } from "../../../../constants/mixins";
import moment from "moment";

export default {
  name: "ViewsColumn",
  props: ["user"],
  mixins: [filterMixin, onClickMixin],
  computed: {
    monthlyAverage: function() {
      if (this.age) {
        return (this.user.videoViews / this.age).toFixed(0);
      }
      return this.user.videoViews;
    },
    age: function() {
      return moment().diff(moment(this.user.createdAt), "months");
    }
  },
};
</script>
<style lang="less" scoped>
.views-amount {
  font-weight: 500;
}
.months-amount {
  color: #637c8e;
}
.views-info {
  display: flex;
  flex-direction: column;
}
</style>
