<template>
    <td class="related-users-column">
        <div
            v-if="user.relatedActiveUsers || user.relatedAllUsers"
            class="related-users-info"
        >
            <router-link :to="`/admin/account-users/${user.id}`">
                <span class="active-users">{{
                    relatedActiveUsers
                }}</span>
            </router-link>
            <span class="all-users">{{ `/ ${relatedAllUsers}` }}</span>
        </div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
import { USERS_TABLE_PARAMS } from '../../../../constants/index';
import { mapState } from 'vuex';

export default {
    name: 'RelatedUsersColumn',
    props: ['user'],
    computed: {
        relatedActiveUsers: function() {
            return this.user.relatedActiveUsers
                ? this.user.relatedActiveUsers
                : '-';
        },
        relatedAllUsers: function() {
            return this.user.relatedAllUsers ? this.user.relatedAllUsers : '-';
        },
    },
};
</script>
<style lang="less" scoped>
.related-users-column {
    .active-users {
        color: #00acdc;
        cursor: pointer;
    }
}
</style>
