<template>
  <div class="pagination-menu">
    <div class="view-option">
      <div class="view-text">View</div>
      <div class="view-options">
        <select
          class="view-select"
          :value="pagination.limit"
          @change="onSelect($event)"
        >
          <option
            class="select-option"
            :value="item"
            v-for="item in selectUserRowsAmountOptions"
            :key="`paginate_to_${item}`"
          >
            {{ item }}
          </option>
        </select>
      </div>
    </div>

    <div class="pages-option">
      <button
        v-if="isPrevButtonShow()"
        @click="clickPrevious($event)"
        class="button-previous"
      ></button>
      <div class="option-details">
        {{ renderLabel() }}
      </div>
      <button
        v-if="isNextButtonShow()"
        class="button-next"
        @click="clickNext($event)"
      ></button>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { PAGINATION_LIMITS } from '../../../constants';

export default {
  name: 'TablePagination',
  props: ['pagination'],
  methods: {
    renderLabel() {
      const { limit, offset } = this.pagination;
      const from = offset;
      const to =
        limit + offset > this.allUsers
          ? this.allUsers
          : limit + offset;

      return `${from + 1} - ${to} of ${this.allUsers}`;
    },
    isPrevButtonShow() {
      const { offset } = this.pagination;
      return offset > 0;
    },
    isNextButtonShow() {
      const { offset, limit } = this.pagination;
      return limit + offset < this.allUsers;
    },
    clickNext() {
      const { offset, limit } = this.pagination;
      this.$emit('on-change', {
        limit: this.pagination.limit,
        offset: offset + limit
      });
    },
    clickPrevious() {
      const { offset, limit } = this.pagination;

      this.$emit('on-change', {
        limit: this.pagination.limit,
        offset: offset - limit >= 0 ? offset - limit : 0
      });
    },
    onSelect($event) {
      this.$emit('on-change', {
        limit: parseInt($event.target.value, 10),
        offset: this.pagination.offset
      });
    }
  },
  computed: {
    ...mapState({
      allUsers: state => state.users.usersCount
    })
  },
  data: () => {
    return {
      selected: PAGINATION_LIMITS[0],
      selectUserRowsAmountOptions: PAGINATION_LIMITS
    };
  }
};
</script>
<style lang="less" scoped>
.pagination-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  height: 200px;

  .view-option {
    display: flex;
    width: 120px;
    border-radius: 20px;
    border: 1px solid #c8e9f2;
    justify-content: center;
    height: 30px;

    .view-text,
    .view-options {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .view-text {
      border-right: 1px solid #c8e9f2;
    }
    .view-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url('../../../assets/img/admin/dropdown-icon.png');
      background-position: center;
      background-position-x: 90%;
      background-repeat: no-repeat;
      width: 35px;
      border: none;
      cursor: pointer;

      .select-option {
        background-color: #ffffff;
      }
    }

    .view-select:focus {
      outline: none;
    }
  }

  .pages-option {
    .view-option();
    min-width: 120px;
    width: auto;
    .button-next {
      outline: none;
      border: none;
      border-left: 1px solid #c8e9f2;
      background-color: transparent;
      background-image: url('../../../assets/img/admin/arrow-right.png');
      background-repeat: no-repeat;
      background-position: center;
      width: 35px;
    }

    .button-previous {
      .button-next();
      border-left: none;
      border-right: 1px solid #c8e9f2;
      background-image: url('../../../assets/img/admin/arrow-left.png');
    }

    .option-details {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
    }
  }
}
</style>
