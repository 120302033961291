<template>
  <td @click="onClick(user.id, user.name)" class="projects-column">
    <div v-if="user.projectsCount" class="projects-number">
      {{ `${user.projectsCount} Projects` }}
    </div>
    <div v-else>&mdash;</div>
    <div v-if="user.videosCount" class="videos-number">
      {{ `${user.videosCount} Videos` }}
    </div>
    <div v-else>&mdash;</div>
  </td>
</template>
<script>
import { onClickMixin } from '../../../../constants/mixins';

export default {
  name: 'ProjectsColumn',
  props: ['user'],
  extends: onClickMixin
};
</script>
<style lang="less" scoped>
.projects-number {
  font-weight: 500;
}
</style>
