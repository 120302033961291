<template>
    <td @click="onClick(user.id, user.name)" class="contact-size-column">
        <div v-if="user.role === roles.OWNER.value" :class="noContacts" class="contacts-number">
            {{ user.contactSize | formatFigure }}
        </div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
import {filterMixin, onClickMixin} from '../../../../constants/mixins';
import { USER_ROLES } from '../../../../constants/index';

export default {
    name: 'ContactSizeColumn',
    props: ['user'],
    mixins: [filterMixin, onClickMixin],
    computed: {
        noContacts: function() {
            return this.user.contactSize ? '' : 'absent';
        }
    },

data: () => {
    return {
        roles: USER_ROLES
    }
}
};
</script>
<style lang="less" scoped>
.contacts-number {
    color: #00acdc;
    font-size: 14px;
    font-weight: 500;
}
.absent {
    color: #21455E;
}
</style>
