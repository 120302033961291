<template>
    <td class="tags-column">
        <div v-if="tagsPresent" class="tags-container">
            <div v-if="user.tags.length" class="tags">
                <div class="line-one">
                    <div class="tag" v-for="tag in tagLines.lineOne" :key="tag">
                        {{ tag }}
                    </div>
                </div>
                <div class="line-two">
                    <div class="tag" v-for="tag in tagLines.lineTwo" :key="tag">
                        {{ tag }}
                    </div>
                    <div class="button-block" v-if="tagsToShow">
                        <div class="button-show-more">
                            {{ `+${tagsToShow} More` }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>&mdash;</div>
        </div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
export default {
    name: 'TagsColumn',
    props: ['user'],
    data: () => {
        return {
            showButtonMore: true,
            buttonMoreMinWidth: 65,
            tagLines: {
                lineOne: [],
                lineTwo: []
            },
            cellWidth: 280,
            spaceLeft: 0,
            tagsToShow: 0,
            tagsCopy: []
        };
    },
    computed: {
        tagsPresent: function() {
            const arr = JSON.parse(this.user.tags).filter(Boolean)
            return !!arr.length
        }
    },
    mounted() {
        this.calculateTags();
        for (let line in this.tagLines) {
            this.fillLine(this.tagLines[line]);
        }
        this.defineButtonStatus(this.spaceLeft);
    },
    methods: {
        countElWidth(amount) {
            return amount * 6 + 1 + 50;
        },
        fillLine(line) {
            if (!this.tagsCopy.length) {
                return;
            }
            let cellWidth = this.cellWidth;
            while (cellWidth - this.countElWidth(this.tagsCopy[0].length) > 0) {
                line.push(this.tagsCopy[0]);
                cellWidth -= this.countElWidth(this.tagsCopy[0].length);
                this.tagsCopy.shift();
            }
            this.spaceLeft = cellWidth;
        },
        defineButtonStatus(freeSpace) {
            if (this.tagsCopy.length) {
                this.showButtonMore = true;
                if (freeSpace < this.buttonMoreMinWidth) {
                    this.tagsCopy.push(this.tagLines.lineTwo.pop());
                }
            } else {
                this.showButtonMore = false;
            }
            this.tagsToShow = this.tagsCopy.length;
            this.calculateTags();
        },
        calculateTags() {
            let tags = JSON.parse(this.user.tags || '[]');
            this.tagsCopy = [...tags.filter(Boolean)];
        }
    }
};
</script>
<style lang="less" scoped>
.tags-column {
    width: 280px;
    .tags-container {
        padding: 13px 0;
        height: 90px;
        overflow: hidden;
    }
}
.tags {
    display: flex;
    flex-wrap: wrap;
    .line-one,
    .line-two {
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        display: flex;
        width: 100%;
    }
    .line-two {
        margin-top: 7px;
    }
    .button-block {
        display: flex;
        align-items: center;
        padding-left: 3px;
    }
    .tag {
        padding: 6px 20px;
        background-color: #f5f7f8;
        text-transform: capitalize;
        border-radius: 20px;
        font-size: 12px;
        margin-right: 10px;
    }
}
</style>
