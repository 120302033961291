<template>
  <div class="search-input-block">
    <input
      type="text"
      class="search-input-box"
      :placeholder="placeholder"
      v-model="searchText"
      @keyup="handleChange()"
    />
  </div>
</template>
<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

const DEBOUNCE_TIME = 300;
const SEARCH_MIN = 3;

export default {
  name: "UserSearchBar",
  props: ["onChange", "placeholder"],

  computed: {
    path: function() {
      return this.$route.path;
    }
  },
  watch: {
      path: function() {
          this.searchText = ''
      }
  },
  methods: {
    handleChange: debounce(function() {
      if (this.searchText.length >= SEARCH_MIN || !this.searchText.length) {
        this.$store.commit("addSearchText", this.searchText);
      }
    }, DEBOUNCE_TIME)
  },
  data: () => ({
    searchText: ""
  })
};
</script>
<style lang="less" scoped>
.search-input-block {
  flex-basis: 29%;
  .search-input-box {
    border-radius: 20px;
    background-image: url("../../../assets/img/stage/icon_search.svg");
    background-position: 8px 8px;
    background-repeat: no-repeat;
    outline: none;
    border: 1px solid #99d6e9;
    padding-left: 30px;
    height: 36px;
    width: 280px;
  }

  .search-input-box::placeholder {
    color: #a3bac6;
    font-size: 13px;
  }
}
</style>
