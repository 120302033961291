<template>
    <td class="compliance-column">
        <div
            v-if="user.compliance"
            @click="onIssueClick"
            class="compliance-info"
        >
            {{ user.compliance | formatForm }}
        </div>
        <div class="no-issues" v-else>No issues</div>
        <custom-modal @close-modal="showModal = false" v-if="showModal">
            <span slot="heading">compliance record</span>
            <compliance-table :issues="issues" slot="body"></compliance-table>
        </custom-modal>
    </td>
</template>
<script>
import CustomModal from '../../../UI/CustomModal';
import ComplianceTable from '../../ComplianceTable';
import { mapState } from 'vuex';
import {filterMixin} from '../../../../constants/mixins';

export default {
    name: 'ComplianceColumn',
    props: ['user'],
    mixins: [filterMixin],
    components: {
        CustomModal,
        ComplianceTable
    },
    data: () => {
        return {
            showModal: false
        };
    },
    computed: {
        ...mapState({
            issues: state => state.complianceIssues
        })
    },
    methods: {
        onIssueClick() {
            this.showModal = true;
            this.$store.dispatch('getComplianceIssues', this.user.id)
        }
    },
};
</script>
<style lang="less" scoped>
.compliance-column {
    font-size: 14px;
    font-weight: 500;
}
.compliance-info {
    color: #00acdc;
    cursor: pointer;
}
</style>
