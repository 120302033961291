<template>
  <td @click="onClick(user.id, user.name)" class="subscription-status">
    <div
      class="data-available"
      v-if="(user.status && user.cycle) || user.renewalDue"
    >
      <div class="status">
        <div class="status-text">Status:</div>
        <div
          v-if="user.status"
          :class="statuses[user.status]"
          class="status-name"
        >
          {{ user.status }}
        </div>
        <span v-else>&mdash;</span>
      </div>
      <div class="cycle">
        <div class="cycle-text">
          Cycle:

          <span v-if="user.cycle" class="cycle-text">{{
            ` ${user.cycle}`
          }}</span>
          <span v-else>&mdash;</span>
        </div>
      </div>
      <div class="renewal-due">
        <div class="renewal-text">
          {{ `Renewal due: ` }}
          <span v-if="user.renewalDue" class="renewal-due-date">
            {{ user.renewalDue | formatDate }}
          </span>
          <span v-else class="renewal-due-dash">&mdash;</span>
        </div>
      </div>
    </div>
    <div v-else>&mdash;</div>
  </td>
</template>
<script>
import moment from 'moment';
import { STATUSES, DATE_FORMATS } from '../../../../constants/index';
import { onClickMixin } from '../../../../constants/mixins';

export default {
  name: 'SubscriptionStatusColumn',
  props: ['user'],
  extends: onClickMixin,
  data: () => {
    return {
      statuses: STATUSES
    };
  },
  filters: {
    formatDate: function(date) {
      return moment(date).format(DATE_FORMATS.renewalDueDate);
    }
  }
};
</script>
<style lang="less" scoped>
.subscription-status {
  font-size: 13px;
  .status,
  .cycle,
  .renewal-due {
    flex-basis: 100%;
    display: flex;
    align-items: center;
  }
  .cycle {
    margin: 6px 0;
    .cycle-text {
      text-transform: capitalize;
    }
  }
  .status-name {
    height: 25px;
    border-radius: 20px;
    padding: 3px 8px;
    margin-left: 5px;
    min-width: 70px;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .grace {
    color: #f27500;
    background-color: #fffaf5;
  }
  .active {
    color: #0ad688;
    background-color: #f5fefa;
  }
  .suspended {
    color: #ee5951;
    background-color: #fff8f8;
  }
  .free, .inactive, .trial {
    background-color: #f6f7f9;
  }
}
</style>
