<template>
  <div class="select-options">
    <div class="checkbox-items">
      <div
        class="checkbox-item"
        :data-name="column.name"
        :data-id="column.id"
        v-for="column in columns"
        :key="column.id"
      >
        <input
          class="checkbox-input"
          :id="column.id"
          type="checkbox"
          :disabled="column.name == 'User'"
          :value="column.name"
          :checked="checkedColumns.includes(column.name)"
        />
        <label
          @click="columnClick(column.name)"
          class="checkbox-text"
          :for="column.id + column.name"
        >
          <div :class="{
              'checked-icon': true,
              'column-user-disabled': column.name === 'User'}">
            <checked-icon v-if="checkedColumns.includes(column.name) || column.name === 'User'"></checked-icon>
            <div class="checked-icon__empty" v-else></div>
          </div>
          {{ column.name }}
        </label>
      </div>
    </div>
    <div class="reset-defaults">
      <div @click="resetDefaultsClick" class="reset-defaults-text">Reset defaults</div>
    </div>
  </div>
</template>
<script>
import testData from "../mockData.json";
import { mapState } from "vuex";
import { USERS_TABLE_PARAMS } from "../../../constants/index";
import CheckedIcon from "../SvgComponents/CheckedIcon";

export default {
  name: "ColumnItems",
  components: {
    CheckedIcon
  },
  computed: {
    ...mapState({
      columns: state => state[state.currentSection].availableColumns,
      checkedColumns: state => state[state.currentSection].checkedColumnsForMenu
    }),
    stateSection: function() {
      const section = this.$route.params.section;
      return USERS_TABLE_PARAMS[section].stateSection;
    },

  },
  methods: {
    columnClick(name) {
      if (name !== "user") {
        this.$store.commit("checkColumn", { name, section: this.stateSection });
      }
    },
    resetDefaultsClick() {
      this.$store.dispatch("resetDefaults", this.stateSection);
      this.$emit("reset-defaults-click");
    },
  }
};
</script>
<style lang="less" scoped>
.checkbox-items {
  padding: 0 10px;
  margin-right: 10px;
  max-height: 450px;
  overflow-y: auto;
}

    .checked-icon {
      margin-right: 15px;
      display: flex;
      align-items: center;

      &__empty {
        width: 17.4px;
        height: 17.4px;
        border: 1px solid#A3BAC6;
        border-radius: 5px;
      }
    }


.checkbox-items::-webkit-scrollbar {
  background-color: #ffffff;
  width: 9px;
}
.checkbox-items::-webkit-scrollbar-thumb {
  background-color: #c9edf8;
  border-radius: 40px;
}
.checkbox-item {
  padding: 7px;
  .checkbox-input {
    display: none;
  }

  .checkbox-text {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

//   .checkbox-input:checked + label::before {
//     background-image: url("../../../assets/img/admin/checked.png");
//     background-size: cover;
//     border: none;
//   }

//   .checkbox-input:disabled + label::before {
//     background-image: url("../../../assets/img/admin/checkdisabled.png");
//   }

//   .checkbox-text::before {
//     content: "";
//     display: block;
//     width: 20px;
//     height: 20px;
//     border-radius: 9%;
//     border: 1px solid #21455e;
//     margin-right: 7px;
//   }



  .checkbox-text::after {
    content: "";
    width: 8px;
    height: 20px;
    display: none;
    background-image: url("../../../assets/img/admin/dragarrow.png");
    background-repeat: no-repeat;
    background-position: center;
    margin-left: auto;
  }
}

.checkbox-item:hover .checkbox-text::after {
  display: block;
}

.checkbox-text:hover {
  cursor: pointer;
}

.reset-defaults {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 20px 0;
  .reset-defaults-text {
    color: #00acdc;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
