<template>
    <td @click="onClick(user.id, user.name)" class="plan-column">
        <div class="data-available" v-if="user.role === roles.OWNER.value">
            <div class="plan-name">{{ user.plan }}</div>
            <div class="first-upgraded">
                First upgraded:
                <span v-if="user.firstUpgrade">{{
                    user.firstUpgrade | formatDate
                }}</span
                ><span v-else>&mdash;</span>
            </div>
            <div class="last-renewed">
                Last renewed:
                <span v-if="user.lastRenewed">{{
                    user.lastRenewed | formatDate
                }}</span
                ><span v-else>&mdash;</span>
            </div>
            <div
                v-if="user.plan === 'business' || user.plan === 'enterprise'"
                class="account-manager"
            >
                <div class="account-manager-text">
                    Account Manager:
                    <span v-if="user.accountManager" class="manager-name">{{
                        user.managerAccount
                    }}</span
                    ><span v-else>&mdash;</span>
                </div>
            </div>
        </div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
import moment from 'moment';
import { DATE_FORMATS, USER_ROLES } from '../../../../constants/index';
import { onClickMixin } from '../../../../constants/mixins';

export default {
    name: 'PlanColumn',
    props: ['user'],
    mixins: [onClickMixin],
    filters: {
        formatDate: function(date) {
            return moment(date).format(DATE_FORMATS.renewalDueDate);
        }
    },
    data: () => {
        return {
            roles: USER_ROLES
        }
    }
};
</script>
<style lang="less" scoped>
.plan-name {
    font-weight: 500;
}
</style>
