<template>
    <td @click="onClick(user.id, user.name)" class="show-date-column">
        <div v-if="date">
            <div class="calendar-text">{{ date | formatToDay }}</div>
            <div class="calendar-date">
                {{ date | formatToDate }}
            </div>
        </div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
import moment from 'moment';
import { DATE_FORMATS } from '../../../../constants/index';
import {filterMixin, onClickMixin} from '../../../../constants/mixins';

export default {
    name: 'ShowDateColumn',
    props: ['date', 'user'],
    mixins: [filterMixin, onClickMixin],
    filters: {
        formatToDate: function(date) {
            let day = moment(date).format(DATE_FORMATS.lastActivity);
            return day;
        }
    },
};
</script>
<style lang="less" scoped>
.calendar-text {
    font-weight: 500;
}
</style>
