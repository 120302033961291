<template>
    <td class="show-address-column">
        <div v-if="role === roles.OWNER.value" class="address-info">{{ address }}</div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
import { USER_ROLES } from '../../../../constants/index';

export default {
    name: "ShowWebAddressColumn",
    props: ['address', 'role'],
    data: () => {
        return {
            roles: USER_ROLES
        }
    }
}
</script>
<style lang="less" scoped>
.address-info {
    color: #00ACDC;
    font-size: 14px;
    font-weight: 500;
}
</style>