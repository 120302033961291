<template>
  <td class="user-data">
    <div class="user-photo">
      <img
        class="avatar-img"
        v-if="user.avatar"
        :src="user.avatar"
        alt="avatar"
      />
      <img
      v-if="!user.avatar"
        class="avatar-img"
         src="../../../../../static/img/nopic.png"
        alt="avatar"
      />
    </div>
    <div class="info">
      <div class="user-name" @click="onClick(user.id, user.name)" >{{ user.name }}</div>
      <div class="user-mail">{{ user.email }}</div>
      <div v-if="this.$route.params.section !== 'account-users'" class="user-role">
        <div v-if="user.role" class="role-text">
          {{ user.role }}
        </div>
      </div>
    </div>
  </td>
</template>
<script>
import { NO_AVATAR } from '../../../../constants/index';
import { onClickMixin } from '../../../../constants/mixins';

export default {
  name: 'UserColumn',
  props: ['user'],
  extends: onClickMixin,
  data: () => {
    return {
      pic: NO_AVATAR
    };
  },
};
</script>
<style lang="less" scoped>
.user-data {
  display: flex;
  min-height: 92px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;

  .user-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-right: 20px;

    .avatar-img {
      object-fit: cover;
      width: inherit;
      height: inherit;
      border-radius: 50%;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    .user-name {
      color: #00acdc;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
    .user-mail {
      font-size: 13px;
      margin: 6px 0;
    }
    .user-role {
      display: flex;

      .role-text {
        background-color: #f6f7f9;
        border-radius: 20px;
        text-transform: uppercase;
        font-size: 11px;
        min-width: 81px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }
    }
  }
}
</style>
