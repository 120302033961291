<template>
  <div class="tags-list">
    <div class="all-tags-item">
      <div class="check-mark-block">
        <img
          src="../../../assets/img/admin/checkmark.png"
          v-if="!selectedTagOption"
          class="check-mark"
        />
      </div>
      <div
        @click="onClick()"
        :class="!selectedTagOption ? 'bold-tag-text' : null"
        class="all-tags-text tag-name"
      >
        All Tags
      </div>
      <div class="amount-with-tag ml-auto">{{ defaultTag }}</div>
    </div>
    <div
      v-for="item in customTagsList"
      :key="item.id"
      @click="onClick(item.tag)"
      class="tag-item"
    >
      <div class="check-mark-block">
        <img
          src="../../../assets/img/admin/checkmark.png"
          v-if="selectedTagOption === item.tag"
          class="check-mark"
        />
      </div>
      <div
        :class="selectedTagOption === item.tag ? 'bold-tag-text' : null"
        class="tag-name"
      >
        {{ item.tag }}
      </div>
      <div class="amount-with-tag ml-auto">{{ item.usersCount }}</div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'TagItems',
  methods: {
    onClick: function(value) {
      this.$store.commit('changeTagOption', value);
      this.$emit('on-change', value);
    }
  },
  computed: {
    ...mapState({
      selectedTagOption: state => state.tags.selectedTagOption,
      usersWithAllTags: state => state.tags.usersWithAllTags,
      customTagsList: state => state.tags.customTagsList
    })
  },
  mounted() {
    this.$store.dispatch('getUsersWithAllTags');
  },
  data: () => {
    return {
      defaultTag: 'All tags'
    };
  }
};
</script>
<style lang="less" scoped>
.tags-list {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 12px;
  width: 263px;
  scrollbar-color: #ffffff #c9edf8; //for Firefox
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
  margin-bottom: 25px;
}

.tags-list::-webkit-scrollbar {
  background-color: #ffffff;
  width: 9px;
}

.tags-list::-webkit-scrollbar-thumb {
  background-color: #c9edf8;
  border-radius: 40px;
}

.all-tags-item,
.tag-item {
  flex-basis: 100%;
  display: flex;
  margin-top: 15px;
  cursor: pointer;
  .bold-tag-text {
    font-weight: 500;
  }
  .amount-with-tag {
    color: #0baed9;
    font-size: 13px;
    font-weight: 500;
  }
  .check-mark-block {
    flex-basis: 15%;
    display: flex;
    justify-content: center;
    .check-mark {
      object-fit: none;
    }
  }
}

.all-tags-item {
  margin-top: 0;
}
</style>
