<template>
    <table class="table-columns">
        <tr class="table-header">
            <th
                :class="'header-cell ' + column"
                v-for="column in columns"
                :key="column"
            >
                {{ column }}
            </th>
        </tr>
        <tr
            class="table-row"
            :class="rowClass"
            v-for="user in users"
            :key="user.id"
        >
            <slot v-for="column in columns" :name="column" :user="user"> </slot>
        </tr>
    </table>
</template>
<script>
import moment from 'moment';

export default {
    name: 'TableColumns',
    props: ['users', 'columns'],
    computed: {
        rowClass: function() {
            const classes = ['active', 'suspended', 'deleted', 'account-users'];
            const currentClass = classes.find(item =>
                this.$route.path.includes(item)
            );
            return currentClass;
        }
    },
};
</script>
<style lang="less">
.table-columns {
    width: 1200px;
    padding: 0 30px;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    .table-header {
        .user {
            padding-left: 109px;
        }
        .header-cell {
            vertical-align: middle;
            height: 45px;
            background-color: #fafdfe;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 500;
        }
    }

    .active, .account-users {
        background-color: #ffffff;
        border-bottom: 1px solid #f1f1f3;
    }

    .suspended {
        background-color: #fde9d6;
        border-bottom: 1px solid #f8b97f;
    }

    .deleted {
       background-color: #FCE3E2;
       border-bottom: 1px solid #EE5951;
    }
    .table-row {
        height: 90px;

        .calendar-text {
            font-weight: 500;
        }
    }
    .location {
        font-size: 14px;
        font-weight: 500;
    }
    .active:hover, .account-users:hover {
        background-color: #ebf9fa;
    }
    .suspended:hover {
        background-color: #fedabc;
    }
    .deleted:hover {
        background-color: #fccecc;
    }
}

tr td:first-child,
tr th:first-child {
    padding-left: 40px;
}

tr td:last-child,
tr th:last-child {
    padding-right: 20px;
}
</style>
