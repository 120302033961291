<template>
    <div class="users">
        <users-general-panel
            :showButton="params.showButton"
            :usersType="params.title"
            :title="params.buttonTitle"
            :placeholder="params.placeholder"
        ></users-general-panel>
        <users-table />
    </div>
</template>
<script>
import UsersCount from './UsersCount';
import UsersTable from './UsersTable';
import UsersGeneralPanel from '../UsersGeneralPanel';
import { mapState } from 'vuex';
import { USERS_TABLE_PARAMS } from '../../../constants/index';

export default {
    name: 'Users',
    components: {
        UsersTable,
        UsersGeneralPanel
    },
    computed: {
        ...mapState({
            usersCount: state => state.users.usersCount
        }),
        params: function() {
            const section = this.$route.params.section;
            return USERS_TABLE_PARAMS[section] ?? {};
        },
    },
    created() {
        const section =
            USERS_TABLE_PARAMS[this.$route.params.section] ? USERS_TABLE_PARAMS[this.$route.params.section].stateSection : USERS_TABLE_PARAMS['active'].stateSection;
        this.$store.commit('addCurrentSection', section);
    },
};
</script>
<style lang="less" scoped>
.users {
    background-color: #f9fbfb;
    min-height: 100vh;
}
</style>
