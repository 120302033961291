<template>
    <td @click="onClick(user.id, user.name)" class="storage-column">
        <div v-if="user.role === roles.OWNER.value" class="storage-info">
            <div class="storage-text">
                <span class="storage-spent">{{
                    formatSize(user.usedStorage, 0, 2)
                }}</span>
                /
                <span class="storage-allowed">{{
                    formatSize(user.availableStorage, 0, 0)
                }}</span>
            </div>
            <div v-if="withOverage" class="overage">Overage</div>
        </div>
        <div v-else>&mdash;</div>
    </td>
</template>
<script>
import { onClickMixin, filterMixin } from '../../../../constants/mixins';
import { USER_ROLES } from '../../../../constants/index';

export default {
    name: 'StorageColumn',
    props: ['user'],
    extends: onClickMixin,
    data: () => {
        return {
            roles: USER_ROLES
        }
    },
    computed: {
        withOverage: function() {
            return this.user.storageSpent > this.user.storageAllowed;
        }
    },
};
</script>
<style lang="less" scoped>
.storage-info {
    display: flex;
    align-items: baseline;
}
.storage-spent {
    font-weight: 500;
    font-size: 14px;
    color: #21455e;
}
.storage-allowed {
    color: #637c8e;
}
.overage {
    margin-left: 10px;
    color: #ee5951;
    background-color: #fff8f8;
    border-radius: 20px;
    padding: 5px 16px;
    font-size: 12px;
}
</style>
